const initialState = {
    loading: false,
    data: [],
    fillStepData:{
        "processType": null,
        "roleName": null,
        "experienceYears": null,
        "noOfTalents": 1,
        "isHiringLimited": null,
        "availability": null,
        "contractDuration": null,
        "skills": null,
        "allSkills": null,
        "currency": null,
        "currencySign": null,
        "budgetFrom": null,
        "budgetTo": null,
        "employmentType": 'Full Time',
        "howSoon": null,
        "workingModeID": null,
        "companyLocation": null,
        "achievementID": null,
        "timezone_Preference_ID": null,
        "timeZoneId": null,
        "timeZone_FromTime": null,
        "timeZone_EndTime": null,
        "isHrTypeDP": null,
        "reason": null,
        "timeZone": null,
        "region": null,
        "rolesResponsibilities": null,
        "requirements": null,
        "jdFileName": null,
        "currentStepId": null,
        "nextStepId": null,        
        "isT_TimeZone_FromTime": null,
        "isT_TimeZone_EndTime": null,
        "city": null,
        "state": null,
        "postalCode": null,
        "country": null,
        "countryID": null,
        "budgetType": null,
        "gptJdId": null,
        "guid": null,
        "ipAddress": null,
        "hiringTypePricingId": null,
        "payrollTypeId": null,
        "payrollPartnerName": null,
        "contactId": (() => {
          const userData = localStorage.getItem('user');
          if (userData && userData !== 'undefined') {
              const parsedUserData = JSON.parse(userData);
              return parsedUserData?.LoggedInUserID ?? null;
          } else {
              return null;
          }
      })(),
        "noBudgetBar":null
    },
    step0Data:{
      uploadJD:null,
      link:"",
      text:""
    },
    error: null    
};

const newRegistrationStepsReducer = (state = initialState, action) => {

    switch (action.type) {
      case 'FETCH_REGISTRATION_REQUEST':
        return { ...state, loading: true, error: null };
      case 'FETCH_REGISTRATION_SUCCESS':
        return { ...state, loading: false, data: action.payload,fillStepData:action?.payload?.JobPreview,fillCompanyData:action?.payload?.StandOutDetails
          , error: null };
      case 'FETCH_REGISTRATION_FAILURE':
        return { ...state, loading: false, data: [], error: action.payload };
      case 'FETCH_REGISTRATION_COMPLETE':
        return { ...state, loading: false };      
      case 'InternalStateManage' :
        return {...state,fillStepData:action.payload};   
      case 'STEP0DATASAVE':
        return {...state,step0Data:action.payload}      
      case "RESET":
        return initialState
      default:
        return state;
    }
  }; 
  
  export default newRegistrationStepsReducer;