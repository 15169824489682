const initialState = {
    loading: false,
    data: [],
    error: null,
    step1Data:{},
    step2Data:{},
    step3Data:{},
    jdLinkAllSkills:[],
    jdLinkSkills:[]
  };
  
  const registrationReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_ROLE_DATA_REQUEST':
        return { ...state, loading: true, error: null };
      case 'FETCH_ROLE_DATA_SUCCESS':
        return { ...state, loading: false, data: action.payload, error: null };
      case 'FETCH_ROLE_DATA_FAILURE':
        return { ...state, loading: false, data: [], error: action.payload };
      case 'FETCH_ROLE_DATA_COMPLETE':
        return { ...state, loading: false };
      case 'SAVE_STEP_1_SUCCESS' :{
        return {...state,loading:false,step1Data:action.payload}
      }
      case 'SAVE_STEP_2_SUCCESS' : {
        return {...state, loading: false, step2Data: action.payload}
      }
      case 'SAVE_STEP_3_SUCCESS':{
        return {...state, loading: false, step3Data: action.payload}
      }
      case "ROLE_CHANGED_SKILL_RESET":{
        return {...state, step2Data:{...state.step2Data ,skills:null,allSkills:null}}
      }
      case "JDLINKALLSKILLS":{
        return {...state, jdLinkAllSkills:action.payload}
      }
      case "JDLINKSKILLS":{
        return {...state, jdLinkSkills:action.payload}
      }
      case "RESET":{
        return{
          loading: false,
          data: [],
          error: null,
          step1Data:{},
          step2Data:{},
          step3Data:{},
          jdLinkAllSkills:[],
          jdLinkSkills:[]
        }
      }
      default:
        return state;
    }
  }; 
  
  export default registrationReducer;