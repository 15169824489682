const initialState = {
    loading: false,
    data: [],
    error: null,
  };
  
  const socialMediaReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_SIGNUP_SOCIAL_MEDIA_REQUEST':
        return { ...state, loading: true, error: null };
      case 'FETCH_SIGNUP_SOCIAL_MEDIA_SUCCESS':
        return { ...state, loading: false, data: action.payload, error: null };
      case 'FETCH_SIGNUP_SOCIAL_MEDIA_FAILURE':
        return { ...state, loading: false, data: [], error: action.payload };
      case 'FETCH_SIGNUP_SOCIAL_MEDIA_COMPLETE':
        return { ...state, loading: false };
      case 'LOGOUT_SOCIAL_MEDIA':
        return { ...state, data: []};
      default:
        return state;
    }
  }; 
  
  export default socialMediaReducer;