import {  Route,  Routes , Navigate,useLocation } from 'react-router-dom';
import {useEffect, useState, Suspense, lazy } from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NetworkInfo } from './constants/network';
import { Space, Spin} from 'antd';
import { reloadLocalStorageWhileRefresh } from './services/api';
import "./components/Dashboard/dashboard.css"
 import "./components/MyJobPosts/myJobPosts.css"
 import "./components/ShortlistedTalents/shortlistedTalents.css"
 import "./components/Dashboard/ShortListedDashboard/shortListedDashboard.css"
import mixpanel from 'mixpanel-browser';
import Mixpanel, { trackPageView } from './constants/Mixpanel';

const Feedback = lazy(() => import('./components/Feedback/feedback'));
const CalendarDemo = lazy(() => import('./components/GoogleMeet/GoogleMeet'));
const LogoutPage = lazy(() => import('./components/LogoutPage'));
const SetPasswordLink = lazy(() => import('./components/PasswordSet/setPassword'));
const MyJobPosts = lazy(() => import('./components/MyJobPosts/myJobPosts'));
const Users = lazy(() => import('./components/Users/users'));
const CreditListing = lazy(() => import('./components/CreditListing/creditlisting'));
const CompanyDetails = lazy(() => import('./components/CompanyDetails/companyDetails'));
const SiderBar = lazy(() => import('./components/CommonComponents/SideBar/sidebar'));
const SubscriptionPlan = lazy(() => import('./components/CreditListing/subscriptionPlan'));
const ShortlistedTalentsPayPerCredit = lazy(() => import('./components/ShortlistedTalents/shortlistedTalentsPayPerCredit'));
const EditAssismentQuestions = lazy(() => import('./components/AssissmentQuestions/AssismentQuestions'));
const ChangePassword = lazy(() => import('./components/ChangePassword/changePassword'));
const LinkedInCallbackPage =lazy(()=>import('./components/Signup/LinkedInCallback'));
const SignupPage = lazy(() => import('./components/Signup/signup'));
const SigninPage = lazy(() => import('./components/Signin/signin'));
const DashboardPage = lazy(() => import('./components/Dashboard/dashboard'));
const OtpPage = lazy(() => import('./components/Otp/otp'));
const ResetPasswordLink = lazy(() => import('./components/ResetPassword/resetPassword'));

const isAuthenticated = () => {
  let _token = localStorage.getItem("Token"); 
  if (_token) {    
    sessionCreated(); // Create session if token exists
    return true;
  }
  return false;
};

const sessionCreated = () => {  
  const sessionStartTime = new Date().getTime();
  localStorage.setItem('sessionStartTime', sessionStartTime.toString());  
};

const PublicRoute = ({ Component }) => {  
  if(localStorage.getItem('user') === 'undefined') localStorage.clear();
  return isAuthenticated() ? <Navigate to="/dashboard" /> :<Component/>
};

const reloadLocalStorage = async () =>{
  let _atsToken = localStorage.getItem("ATSToken"); 
  let _token = localStorage.getItem("Token");


  let user = localStorage?.getItem("user") ? JSON?.parse(localStorage?.getItem("user")) : null;
  let _userValues = {...user}
  let _companyTypeId = _userValues.CompanyTypeId;

  let payload = {
    token:_token,
    atsToken:_atsToken,
    currentCompanyTypeId : _companyTypeId
  }
  const response = await reloadLocalStorageWhileRefresh(payload);
  localStorage.setItem("user",JSON.stringify(response?.responseBody?.details));  
}

const PrivateRoute = ({Component,isHome,setIsPostJob,isPostJob ,setCreditInfo,creditInfo}) => {
  if(localStorage.getItem('user') === 'undefined') localStorage.clear();

  const location = useLocation();
  return isAuthenticated() ? <>
  <div className='dashboard-sec'>
    {!isHome && <SiderBar setIsPostJob={setIsPostJob} setCreditInfo={setCreditInfo}creditInfo={creditInfo}/>}
     </div><Component setIsPostJob={setIsPostJob} isPostJob={isPostJob} setCreditInfo={setCreditInfo}creditInfo={creditInfo} /> 
     </>: <Navigate to="/login"  replace state={{ redirectTo: location }}/>;
};

function App() { 
  const _isInternal = localStorage.getItem("isInternal");
      if(_isInternal == "true"){
          mixpanel.init(NetworkInfo.MIX_PANEL_TOKEN, { track_pageview: false, ignore_dnt: true });
      }else{
          mixpanel.init(NetworkInfo.MIX_PANEL_TOKEN, { track_pageview: "full-url", ignore_dnt: true });
      }
        
  const location = useLocation();
  useEffect(() => {
    const trackMixpanel = async () =>{    
      if(_isInternal !== "true") { mixpanel.track_pageview();}
    }
   trackMixpanel()
   if(isAuthenticated()){
    reloadLocalStorage();
  }
  }, [location,_isInternal]);

  useEffect(() => {
    if (window.top !== window.self) {
      window.top.location = window.self.location;
    }
  }, []);

    // HotJar 
    useEffect(function(){        
     if(NetworkInfo.Environment==='Live') {       
      (function (h, o, t, j, a, r){
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments)
          };
        h._hjSettings = { hjid: 3671889, hjsv: 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
        })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
      } 

      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-VM9G1PKBGJ');

    },[]);
   
    const[isPostJob,setIsPostJob] = useState(false);
    const[creditInfo,setCreditInfo] = useState(false);

    // MixPanel PageChange Tracking
    // const location = useLocation();
    // useEffect(() => {           
    //   if (!(NetworkInfo.MIX_MIXPANEL_ENABLED) || !NetworkInfo.MIX_PANEL_TOKEN) {
    //     return 
    // }   
    // // trackPageView()     
    // }, [location])
  return (
  <>
  {_isInternal !== "true" &&<Mixpanel/>}
        <Routes>
          <Route path="/" element={<Navigate to="/signup" />} />
          <Route path='/logout' element={
            <Suspense fallback={<Space size="middle"> <Spin size="large" /></Space>}><LogoutPage/></Suspense>  
            } />
          <Route path='/ClientHappinessSurveyFeedback/HappinessSurvey' element={
            <Suspense fallback={<Space size="middle"> <Spin size="large" /></Space>}><Feedback/></Suspense>  
            } />  
          <Route path="/login" element={
                   <Suspense fallback={<Space size="middle">
                   <Spin size="large" />  
               </Space>}>
                  <PublicRoute Component={SigninPage} />
                 </Suspense>
          
         }/>
          <Route path="/signup" element={
                   <Suspense fallback={<Space size="middle">
                   <Spin size="large" />  
               </Space>}>
               <PublicRoute Component={SignupPage}/>
                 </Suspense>
          
         }/> 
          <Route path="/callback" element={<Suspense fallback={<Space size="middle"><Spin size="large" /></Space>}><LinkedInCallbackPage/></Suspense>}/>                  
          <Route
              path='/dashboard'
              element={
                <Suspense fallback={<Space size="middle"><Spin size="large" /></Space>}>
                  <PrivateRoute Component={DashboardPage} setIsPostJob={setIsPostJob} setCreditInfo={setCreditInfo} creditInfo={creditInfo} />
                </Suspense>
              }
            />          
          <Route
            path='/myJobPosts'
            element={
              <Suspense fallback={<Space size="middle"><Spin size="large" /></Space>}>
                <PrivateRoute Component={MyJobPosts} setIsPostJob={setIsPostJob} isPostJob={isPostJob} setCreditInfo={setCreditInfo} creditInfo={creditInfo} />
              </Suspense>
            }
          />            
        <Route
          path='/shortlistedTalents'
          element={
            <Suspense fallback={<Space size="middle"><Spin size="large" /></Space>}>
              <PrivateRoute Component={ShortlistedTalentsPayPerCredit} setIsPostJob={setIsPostJob} setCreditInfo={setCreditInfo} creditInfo={creditInfo} />
            </Suspense>
          }
        />
          <Route
            path='/users'
            element={
              <Suspense fallback={<Space size="middle"><Spin size="large" /></Space>}>
                <PrivateRoute Component={Users} setIsPostJob={setIsPostJob} setCreditInfo={setCreditInfo} creditInfo={creditInfo} />
              </Suspense>
            }
          />
          <Route
            path='/creditlisting'
            element={
              <Suspense fallback={<Space size="middle"><Spin size="large" /></Space>}>
                <PrivateRoute Component={CreditListing} setIsPostJob={setIsPostJob} setCreditInfo={setCreditInfo} creditInfo={creditInfo} />
              </Suspense>
            }
          />
          
          <Route
            path='/companyDetails'
            element={
              <Suspense fallback={<Space size="middle"><Spin size="large" /></Space>}>
                <PrivateRoute Component={CompanyDetails} setCreditInfo={setCreditInfo} setIsPostJob={setIsPostJob} isPostJob={isPostJob}/>
              </Suspense>
            }
          />
          <Route
              path='/subscriptionPlan'
              element={
                <Suspense fallback={<Space size="middle"><Spin size="large" /></Space>}>
                  <PrivateRoute Component={SubscriptionPlan} setIsPostJob={setIsPostJob} setCreditInfo={setCreditInfo} creditInfo={creditInfo} />
                </Suspense>
              }
            />
            <Route
              path='/otp'
              element={
                <Suspense fallback={<Space size="middle"><Spin size="large" /></Space>}>
                 <PublicRoute Component={OtpPage} />
                 </Suspense>
              }
            />
         <Route
              path='/resetPassword'
              element={
                <Suspense fallback={<Space size="middle"><Spin size="large" /></Space>}>
                <PublicRoute Component={ResetPasswordLink} />
                 </Suspense>
              }
            />
              <Route
              path='/setPassword'
              element={
                <Suspense fallback={<Space size="middle"><Spin size="large" /></Space>}>
                <PublicRoute Component={SetPasswordLink} />
                 </Suspense>
              }
            />
              <Route
              path='/googleMeet'
              element={
                <Suspense fallback={<Space size="middle"><Spin size="large" /></Space>}>
                <PublicRoute Component={CalendarDemo} />
                 </Suspense>
              }
            />

            <Route
              path='/creditbase-shortlist'
              element={
                <Suspense fallback={<Space size="middle"><Spin size="large" /></Space>}>
                  <PrivateRoute Component={ShortlistedTalentsPayPerCredit} setIsPostJob={setIsPostJob}  isPostJob={isPostJob}  setCreditInfo={setCreditInfo} creditInfo={creditInfo}/>
                 </Suspense>
              }
            />
            <Route
              path='/assessment-questions/:hrNumber'
              element={
                <Suspense fallback={<Space size="middle"><Spin size="large" /></Space>}>
                   <PrivateRoute Component={EditAssismentQuestions} setIsPostJob={setIsPostJob}  isPostJob={isPostJob}  setCreditInfo={setCreditInfo} creditInfo={creditInfo}/>
                </Suspense>
              }
            />

            <Route
              path='/assessment-questions/:hrNumber/:goBack'
              element={
                <Suspense fallback={<Space size="middle"><Spin size="large" /></Space>}>
                   <PrivateRoute Component={EditAssismentQuestions} setIsPostJob={setIsPostJob}  isPostJob={isPostJob}  setCreditInfo={setCreditInfo} creditInfo={creditInfo}/>
                </Suspense>
              }
            />

          <Route
              path='/changePassword'
              element={
                <Suspense fallback={<Space size="middle"><Spin size="large" /></Space>}>
                   <PrivateRoute Component={ChangePassword} setIsPostJob={setIsPostJob}  isPostJob={isPostJob}  setCreditInfo={setCreditInfo} creditInfo={creditInfo}/>
                </Suspense>
              }
          />
    
  
        </Routes>    
        </>
  );
}

export default App;
