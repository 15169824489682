import { combineReducers } from 'redux';
import signUpReducer from './signUpReducer';
import signInReducer from './signInReducer';
import registrationReducer from './registrationStepsReducer';
import getTalentRolesReducer from './getTalentRoles';
import getAchievementReducer from './getAchievementReason';
import otpReducer from './otpReducer';
import socialMediaReducer from './socialMediaReducer';
import dashboardReducer from './dashboardReducer';
import newRegistrationStepsReducer from './newRegistrationStepsReducer';
import creditRegistrationStepsReducer from './creditFlowRegistrationReducer';
import commonFormStepsReducer from './commonStepsReducer';

const rootReducer = combineReducers({  
    signUp: signUpReducer,
    signIn:signInReducer,
    otp:otpReducer,
    registrationSteps:registrationReducer,
    newRegData:newRegistrationStepsReducer,
    commonFormData:commonFormStepsReducer,
    creditReg:creditRegistrationStepsReducer,
    talentRoles:getTalentRolesReducer,
    achievementReason:getAchievementReducer,
    socialMedia:socialMediaReducer,
    dashboard:dashboardReducer,
});

export default rootReducer;