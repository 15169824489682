import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { clientId } from './constants/socialLoginIds';
import { CookiesProvider } from 'react-cookie';
import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <ClearBrowserCacheBoundary auto={true} fallback='' duration={300000} clearCacheAndReload = {true}>
    <GoogleOAuthProvider clientId={clientId}>
      <BrowserRouter>
        <Provider store={store}>
        <CookiesProvider defaultSetOptions={{ path: '/' }}>
          <App />
        </CookiesProvider>
        </Provider>    
      </BrowserRouter>
    </GoogleOAuthProvider>
    </ClearBrowserCacheBoundary>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
