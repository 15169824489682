import {  trackingDetailsAPI } from "../constants/commonUsedVar";
import { NetworkInfo, SubDomain, UserAPI, ClientHappinessSurveyFeedback, On_Board } from "../constants/network";
import { HttpServices } from "./http/http_service";


export const signUpRequest = async (userReqData) => {
  let TrackData = {
    trackingDetails: trackingDetailsAPI()
  };
    try {
        let httpService = new HttpServices();
        httpService.URL = NetworkInfo.NETWORK + SubDomain.ACCOUNT + UserAPI.SIGNUP;
        httpService.dataToSend ={...userReqData,  trackingDetails: { ...TrackData.trackingDetails}}
        let response = await httpService.sendPostRequest();
        return response;
    } catch (error) {
      return error;
    }
};

export const saveEnquiry = async (userReqData) => {
  try {
    let httpService = new HttpServices();
    httpService.URL = NetworkInfo.NETWORK + SubDomain.ACCOUNT + UserAPI.SAVEENQUIRY;
    httpService.dataToSend = userReqData;
    let response = await httpService.sendPostRequest();
    return response;
  } catch (error) {
    return error 
  }

}

export const signInRequest = async (userReqData) => {
  let TrackData = {
    trackingDetails:trackingDetailsAPI()
  };

  try {
    let httpService = new HttpServices();
    httpService.URL = NetworkInfo.NETWORK + SubDomain.ACCOUNT + UserAPI.LOGIN;
    httpService.dataToSend ={...userReqData,  trackingDetails: { ...TrackData.trackingDetails}}
    let response = await httpService.sendPostRequest();
    return response;    
  } catch (error) {
    // console.log(error,"error");
    return error;
  }
}

export const signInRequestSSO = async (userReqData) => {
  let TrackData = {
    device: "",
    location: "",
    iP_Address: "",
    browser: ""
  }

      try {
        const response = await fetch('https://ipinfo.io/json');
        const data = await response.json();

          let temp = `${data?.city},${data?.postal},${data?.region},${data?.country}`;
          let datasplit = temp.split(",");
          const filteredValues = datasplit.filter(value => value !== "null" && value.trim() !== "");
          const result = filteredValues.join(", ");

          const browserDetails = `${window.navigator.userAgent}, ${window.navigator.platform}`;
          TrackData.location = result;
          TrackData.device =  window.navigator.platform;
          TrackData.iP_Address = data?.ip;
          TrackData.browser = browserDetails
      } catch (error) {
        console.log(error,"error")
        // return error;
      }
      try {
        let httpService = new HttpServices();
        httpService.URL = NetworkInfo.NETWORK + SubDomain.ACCOUNT + UserAPI.LOGIN;
        httpService.dataToSend ={...userReqData,  trackingDetails: TrackData}
        let response = await httpService.sendPostRequest();
        return response;    
      } catch (error) {
        // console.log(error,"error");
        return error;
      }
}

export const signUpSocialMedia = async (userInfo) => {
  try {
    let httpService = new HttpServices();
    httpService.URL = NetworkInfo.NETWORK + SubDomain.ACCOUNT + UserAPI.SOCIALMEDIALOGIN;
    httpService.dataToSend = userInfo;
    let response = await httpService.sendPostRequest();
    return response;    
  } catch (error) {
    return error;
  }
}

export const loginFromExosting = async (data)=>{
  try {
    let httpService = new HttpServices();
    httpService.URL = NetworkInfo.NETWORK + SubDomain.ACCOUNT + UserAPI.LOGIN_FORM_EXISTING + `?data=${data}`;
    let response = await httpService.sendGetRequest();
    return response
  }catch(error){
    return error
  }
}

export const RedirectToDashboard = async ()=>{
  try {
    let httpService = new HttpServices();
    httpService.URL = NetworkInfo.NETWORK + SubDomain.ACCOUNT + UserAPI.REDIRECT_TO_DASHBOARD ;
    httpService.setAuthRequired = true;
    httpService.setAuthToken = localStorage.getItem('Token');
    let response = await httpService.sendGetRequest();
    return response
  }catch(error){
    return error
  }

}

// export const LogOut = async ()=>{
//   try {
//     let httpService = new HttpServices();
//     httpService.URL = NetworkInfo.NETWORK + SubDomain.ACCOUNT + UserAPI.LOGOUT + `?token=${localStorage.getItem('Token')}` ;
//     httpService.setAuthRequired = true;
//     httpService.setAuthToken = localStorage.getItem('Token');
//     let response = await httpService.sendGetRequest();
//     return response
//   }catch(error) {
//     return error
//   }
// }

export const LogOut = async ()=>{
  try {
    let httpService = new HttpServices();
    httpService.URL = NetworkInfo.NETWORK + SubDomain.ACCOUNT + UserAPI.LOGOUT  ;
    httpService.setAuthRequired = true;
    httpService.setAuthToken = localStorage.getItem('Token');
    httpService.dataToSend = {
      "token":localStorage.getItem('Token'),
      "atsToken" : localStorage.getItem('ATSToken')
  }
    let response = await httpService.sendPostRequest();
    return response
  }catch(error) {
    return error
  }
}

export const otpVerifyApi = async (otpReqData) => {
  let TrackData = {
    trackingDetails: trackingDetailsAPI()
  };

  try {
    let httpService = new HttpServices();
    httpService.URL =  NetworkInfo.NETWORK + SubDomain.ACCOUNT + UserAPI.VERIFYOTP;
    httpService.dataToSend ={...otpReqData,  trackingDetails: { ...TrackData.trackingDetails}};
    let response = await httpService.sendPostRequest();
    return response;   
  } catch (error) {
    return error
  }
}

export const resendOtpApi = async (resendOtpReq) => {
  try {
    let httpService = new HttpServices();
    httpService.URL =  NetworkInfo.NETWORK + SubDomain.ACCOUNT + UserAPI.RESENDOTP;
    httpService.dataToSend = resendOtpReq;
    let response = await httpService.sendPostRequest();
    return response;   
  } catch (error) {
    return error
  }
}

export const forgotResendLink = async (emailId) => {
  try {
    let httpService = new HttpServices();
    httpService.URL = NetworkInfo.NETWORK + SubDomain.ACCOUNT + UserAPI.FORGOTPASSWORD ;
    httpService.setAuthRequired = true;
    httpService.setAuthToken = localStorage.getItem('Token');
    httpService.dataToSend = {
      email: emailId
    };
    let response = await httpService.sendPostRequest();
    return response
  }catch(error) {
    return error
  }
} 

export const resetPassword = async (reqPayload) => {
  let TrackData = {
    trackingDetails: trackingDetailsAPI()
  };
  try {
    let httpService = new HttpServices();
    httpService.URL = NetworkInfo.NETWORK + SubDomain.ACCOUNT + UserAPI.SETPASSWORD  ;
    httpService.setAuthRequired = true;
    httpService.setAuthToken = localStorage.getItem('Token');
    httpService.dataToSend ={...reqPayload,  trackingDetails: { ...TrackData.trackingDetails}};
    let response = await httpService.sendPostRequest();
    return response
  }catch(error) {
    return error
  }
}

export const setPasswordUrl = async (reqPayload) => {
  try {
    let httpService = new HttpServices();
    httpService.URL = NetworkInfo.NETWORK + SubDomain.ACCOUNT + UserAPI.SET_PASSWORD_URL;
    httpService.setAuthRequired = true;
    httpService.setAuthToken = localStorage.getItem('Token');
    httpService.dataToSend = reqPayload;
    let response = await httpService.sendPostRequest();
    return response
  } catch (error) {
    return error
  }
}

export const loginWithEmail = async (data) => {
  let TrackData = {
    trackingDetails: trackingDetailsAPI()
  };
  try {
    let httpService = new HttpServices();
    httpService.URL = NetworkInfo.NETWORK + SubDomain.ACCOUNT + UserAPI.LOGIN_WITH_EMAIL;
    httpService.setAuthRequired = true;
    httpService.setAuthToken = localStorage.getItem('Token');
    httpService.dataToSend =    {...data,  trackingDetails: { ...TrackData.trackingDetails}};
    let response = await httpService.sendPostRequest();
    return response
  } catch (error) {
    return error
  }
}

// Happiness survay 

export const getHappinessSurvey = async (id) =>{
  try {
    let httpService = new HttpServices();
    httpService.URL = NetworkInfo.NETWORK + SubDomain.CLIENT_HAPPINESS_SURVEY + ClientHappinessSurveyFeedback.HAPPINESS_SURVEY ; ;
    httpService.setAuthRequired = true;
    httpService.setAuthToken = localStorage.getItem('Token');
    httpService.dataToSend = {
      'encryptedID': id
    }
    let response = await httpService.sendPostRequest();
    return response
  } catch (error) {
    return error
  }
}

export const getQuestions = async (rating)=>{
  try {
    let httpService = new HttpServices();
    httpService.URL = NetworkInfo.NETWORK + SubDomain.CLIENT_HAPPINESS_SURVEY + ClientHappinessSurveyFeedback.GET_QUESTION + `?ratings=${rating}`; ;
    httpService.setAuthRequired = true;
    httpService.setAuthToken = localStorage.getItem('Token');
    let response = await httpService.sendGetRequest();
    return response
  } catch (error) {
    return error
  }
}

export const submitFeedbak = async (data) => {
  try {
    let httpService = new HttpServices();
    httpService.URL = NetworkInfo.NETWORK + SubDomain.CLIENT_HAPPINESS_SURVEY + ClientHappinessSurveyFeedback.SUBMIT_FEEDBACK ;
    httpService.setAuthRequired = true;
    httpService.setAuthToken = localStorage.getItem('Token');
    httpService.dataToSend = data
    let response = await httpService.sendPostRequest();
    return response
  } catch (error) {
    return error
  }
}

export const utsFeedBack = async (data) => {
  try {
    let httpService = new HttpServices();
    httpService.URL = NetworkInfo.NETWORK + SubDomain.ACCOUNT + ClientHappinessSurveyFeedback.UTS_FEEDBACK;
    httpService.setAuthRequired = true;
    httpService.setAuthToken = localStorage.getItem('Token');
    httpService.dataToSend = data;
    let response = await httpService.sendPostRequest();
    return response
  } catch (error) {
    return error
  }
}

export const checkCompanyName = async (companyName) => {
  try {
    let httpService = new HttpServices();
    httpService.URL = NetworkInfo.NETWORK + SubDomain.ACCOUNT + UserAPI.CHECK_COMPANY_NAME + `?companyName=${companyName}`;
    httpService.setAuthRequired = true;
    httpService.setAuthToken = localStorage.getItem('Token');
    let response = await httpService.sendGetRequest();
    return response
  } catch (error) {
    return error
  }
}
export const trackingLead = async (data) => {
  try {
    let httpService = new HttpServices();
    httpService.URL = NetworkInfo.NETWORK + SubDomain.ACCOUNT + UserAPI.TRACKING_LEAD ;
    httpService.setAuthRequired = true;
    httpService.setAuthToken = localStorage.getItem('Token');
    httpService.dataToSend = data
    let response = await httpService.sendPostRequest();
    return response
  } catch (error) {
    return error
  }
}

export const reloadLocalStorageWhileRefresh = async (data) => {
  try {
    let httpService = new HttpServices();
    httpService.URL = NetworkInfo.NETWORK + SubDomain.ACCOUNT + UserAPI.RELOAD_LOCALSTORAGE_WHILE_REFRESH;
    httpService.setAuthRequired = true;
    httpService.setAuthToken = localStorage.getItem('Token');
    httpService.dataToSend = data
    let response = await httpService.sendPostRequest();
    return response
  } catch (error) {
    return error
  }
}

export const changePassword = async (data) => {
  try {
    let httpService = new HttpServices();
    httpService.URL = NetworkInfo.NETWORK + SubDomain.ACCOUNT + UserAPI.CHANGE_PASSWORD ;
    httpService.setAuthRequired = true;
    httpService.setAuthToken = localStorage.getItem('Token');
    httpService.dataToSend = data
    let response = await httpService.sendPostRequest();
    return response
  } catch (error) {
    return error
  }
}

export const getUserDetails = async (reqPayload) => {
  try {
    let httpService = new HttpServices();
    httpService.URL = NetworkInfo.NETWORK + SubDomain.ACCOUNT + UserAPI.GET_CONTACT_DETAILS_AUTO_FILL;
    httpService.setAuthRequired = true;
    httpService.setAuthToken = localStorage.getItem('Token');
    httpService.dataToSend = reqPayload;
    let response = await httpService.sendPostRequest();
    return response
  } catch (error) {
    return error
  }
}

export const getPreOnBoardingData = async (payload) => {
  try {
    let httpService = new HttpServices();
    httpService.URL = NetworkInfo.NETWORK + SubDomain.ON_BOARD + On_Board.GET_PRE_ONBOARDING_DETAILS + `?OnBoardId=${payload?.OnBoardId}&HR_ID=${payload?.HR_ID}`;
    httpService.setAuthRequired = true;
    httpService.setAuthToken = localStorage.getItem('Token');
    let response = await httpService.sendGetRequest();
    return response
  } catch (error) {
    return error
  }
}

export const updateOnBoardingDetails = async (reqPayload) => {
  try {
    let httpService = new HttpServices();
    httpService.URL = NetworkInfo.NETWORK + SubDomain.ON_BOARD + On_Board.UPDATE_ONBOARDING_DETAILS;
    httpService.setAuthRequired = true;
    httpService.setAuthToken = localStorage.getItem('Token');
    httpService.dataToSend = reqPayload;
    let response = await httpService.sendPostRequest();
    return response
  } catch (error) {
    return error
  }
}

export const uploadLeavePolicy = async (onBoardId,file) => {
  try {
    let httpService = new HttpServices();
    httpService.URL = NetworkInfo.NETWORK + SubDomain.ON_BOARD + On_Board.UPLOAD_LEAVEPOLICY + `?OnBoardId=${onBoardId}`;
    httpService.setAuthRequired = true;
    httpService.setAuthToken = localStorage.getItem('Token');
    httpService.dataToSend = file;
    let response = await httpService.sendFileDataPostRequest();
    return response
  } catch (error) {
    return error
  }
}