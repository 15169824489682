const initialState = {
    loading: false,
    data: [],
    fillStepData:{},
    companyDetails:{},
    stepOne:{},
    stepTwo:{},
    stepThree:{},
    stepFour:{},
    stepJDData:{
      uploadJD:null,
      link:"",
      text:""
    },
    error: null    
};

const commonFormStepsReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_COMMON_REGISTRATION_REQUEST':
        return { ...state, loading: true, error: null };
      case 'FETCH_COMMON_REGISTRATION_SUCCESS':
        return { ...state, loading: false, data: action.payload,fillStepData:action?.payload?.JobPreview, error: null };
      case 'FETCH_COMMON_REGISTRATION_FAILURE':
        return { ...state, loading: false, data: [], error: action.payload };
      case 'FETCH_COMMON_REGISTRATION_STAGE_1':
        return { ...state, loading: false, stepOne:action.payload };
      case 'FETCH_COMMON_REGISTRATION_STAGE_2':
        return { ...state, loading: false, stepTwo:action.payload };
      case 'FETCH_COMMON_REGISTRATION_STAGE_3':
        return { ...state, loading: false, stepThree:action.payload };
      case 'FETCH_COMMON_REGISTRATION_STAGE_4':
        return { ...state, loading: false, stepFour:action.payload };
      case 'FETCH_COMMON_REGISTRATION_COMPLETE':
        return { ...state, loading: false };      
      case 'FILL_STEPS_DATA' :
        return {...state,fillStepData:action.payload};  
      case 'COMPANY_DETAILS' :
        return {...state,companyDetails:action.payload};
      case 'STEPJDDATASAVE':
        return {...state,stepJDData:action.payload} 
      case "RESET_FORM_DATA":
        return {...state,
          loading: false,
          data: [],
          fillStepData:{},          
          stepOne:{},
          stepTwo:{},
          stepThree:{},
          stepFour:{},
          stepJDData:{
            uploadJD:null,
            link:"",
            text:""
          },
          error: null    
    } 
      default:
        return state;
    }
  }; 
  
  export default commonFormStepsReducer;