import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { useSelector } from "react-redux";
import { NetworkInfo } from './network';
import { useLocation } from 'react-router';
import { getDataFromLocalStorage } from './commonUsedVar';
import { v4 as uuidv4 } from 'uuid';

export const setMixPanelSessionId = async () => {
  if (localStorage.getItem('mixpanel_session_id') == null) {
      localStorage.setItem('mixpanel_session_id', uuidv4())
  }
}

export const trackPageView = (eventName,extraParams) => {
  setMixPanelSessionId();
  const url = window.location.href
  const pathname = window.location.href
  const title_split = document.title.split(NetworkInfo.MIX_APP_NAME + " | ")
  const title = (title_split.length == 1) ? pathname : title_split[1]
  const user = getDataFromLocalStorage();

  const mixpanel_session_id = localStorage.getItem('mixpanel_session_id');

  if (user) {
    mixpanel.identify(user?.LoggedInUserNameTC)
    mixpanel.people.set(user)
  }
  if (eventName !== null) {
    extraParams = extraParams || true;
    mixpanel.track(eventName, {
      url,
      pathname,
      mixpanel_session_id,
      user,
      ...extraParams
    });
  } 

}

const Mixpanel = () => {
  // mixpanel.init(NetworkInfo.MIX_PANEL_TOKEN, { track_pageview: "full-url", ignore_dnt: true });
  const location = useLocation();
  // mixpanel.init(NetworkInfo.MIX_PANEL_TOKEN, { track_pageview: "full-url", ignore_dnt: true });
  useEffect(() => {
    const _isInternal = localStorage.getItem("isInternal");
    if (!(NetworkInfo.MIX_MIXPANEL_ENABLED) || !NetworkInfo.MIX_PANEL_TOKEN) {
          return;
      }
      let user = getDataFromLocalStorage();      
      if (user) {
        const newObj = {
          name: user?.FullName,
          email: user?.LoggedInUserNameTC
        }
        Object.assign(user, newObj);
        if(_isInternal !== "true"){
          mixpanel.identify(user?.LoggedInUserNameTC);
          mixpanel.people.set(user);
        }
    }
  }, [location]);

  return (<></>);
}

// mixpanel.init(NetworkInfo.MIX_PANEL_TOKEN);


let env_check = NetworkInfo.MIX_MIXPANEL_ENABLED;


export const timeTrackEvent = (eventName) => {
  mixpanel.time_event(eventName)
}

export const trackAllEventCTA = (eventName,isClick) =>{
  const _isInternal = localStorage.getItem("isInternal");
  if (!env_check) return
  if(_isInternal !== "true") {trackPageView(eventName,isClick);}
} 

export const trackMixPanelPageViewV2 = (eventName = null, property = null) => {
  const localUserStr = localStorage.getItem('user')
  const isObjectEmpty = (localUserStr) => {
    return Object.keys(localUserStr).length === 0
  }
  const user = (localUserStr) ? JSON.parse(localUserStr) : null
  if (property && property?.isIdentity && user != null && !isObjectEmpty(user)) {
      if (user.LoggedInUserNameTC && !isObjectEmpty(user)) {
          mixpanel.identify(user.LoggedInUserNameTC)
          mixpanel.people.set(user)
      }
  }

  // superProperty
  if (property && property?.superProperty) {
    mixpanel.register(property?.superProperty);
  }

  // unregister superProperty
  if (property && property?.unregister) {
      mixpanel.unregister(property?.unregister);
  }

  //inherit super Property
  if (property && property?.inheritSuperProperty) {
      mixpanel.register_once(property?.inheritSuperProperty);
  }

  if (property && property?.extraParams && eventName != null) {
      mixpanel.track(eventName, property?.extraParams);
  } else {
      mixpanel.track(eventName);
  }

}

export default Mixpanel;