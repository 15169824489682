const initialState = {
    loading: false,
    data: [],
    resendOtpData:[],
    error: null,
  };
  
  const otpReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_OTP_REQUEST':
        return { ...state, loading: true, error: null };
      case 'FETCH_OTP_SUCCESS':
        return { ...state, loading: false, data: action.payload, error: null };
      case 'FETCH_OTP_FAILURE':
        return { ...state, loading: false, data: [], error: action.payload };
      case 'FETCH_OTP_COMPLETE':
        return { ...state, loading: false };
      case 'FETCH_RESEND_OTP_SUCCESS':
        return{...state,loading:false,resendOtpData:action.payload,error:null};
      case 'CLEAR_DATA':
        return { ...state, loading: false, resendOtpData: [],data:[], error: null };
      case 'CLEAR_ERROR_DATA':
        return {...state,loading:false,data:[],error:null}  
      default:
        return state;
    }
  }; 
  
  export default otpReducer;