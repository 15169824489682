const initialState = {
    loading: false,
    data: [],
    user:{},
    error: null,
  };
  
  const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_DASHBOARD_REQUEST':
        return { ...state, loading: true, error: null };
      case 'FETCH_DASHBOARD_SUCCESS':
        return { ...state, loading: false, data: action.payload, error: null };
      case 'FETCH_DASHBOARD_FAILURE':
        return { ...state, loading: false, data: [], error: action.payload };
      case 'FETCH_DASHBOARD_COMPLETE':
        return { ...state, loading: false };         
      case 'FETCH_USER_DATA':
        return {...state,user:action.payload};    
      default:
        return state;
    }
  }; 
  
  export default dashboardReducer;