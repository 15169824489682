const initialState = {
    loading: false,
    data: [],  
    stageOne:{},
    stageTwo:{},
    stageThree:{},
    error: null    
};

const creditRegistrationStepsReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_CREDIT_REGISTRATION_REQUEST':
        return { ...state, loading: true, error: null };
      case 'FETCH_CREDIT_REGISTRATION_SUCCESS':
        return { ...state, loading: false, data: action.payload,fillStepData:action?.payload?.JobPreview, error: null };
      case 'FETCH_CREDIT_REGISTRATION_FAILURE':
        return { ...state, loading: false, data: [], error: action.payload };
      case 'FETCH_CREDIT_STAGE_1':
        return { ...state, loading: false, stageOne:action.payload };
      case 'FETCH_CREDIT_STAGE_2':
        return { ...state, loading: false, stageTwo:action.payload };
      case 'FETCH_CREDIT_STAGE_3':
        return { ...state, loading: false, stageThree:action.payload };
      case 'FETCH_CREDIT_REGISTRATION_COMPLETE':
        return { ...state, loading: false };            
      case "RESET":
        return initialState
      default:
        return state;
    }
  }; 
  
  export default creditRegistrationStepsReducer;