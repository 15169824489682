export class NetworkInfo {
	static PROTOCOL = 'https://';
	static DOMAIN = 'newbeta-clientapi.Uplers.com/' ;
	static ADMINDOMAIN = 'newbeta-adminapi.Uplers.com/'
	static FILEDOMAIN = 'newbeta-adminapi.Uplers.com/';
	static Environment = 'Live';
	static NETWORK = NetworkInfo.PROTOCOL + NetworkInfo.DOMAIN;
	static FILENETWORK = NetworkInfo.PROTOCOL + NetworkInfo.FILEDOMAIN; 
	// static MIX_PANEL_TOKEN = '166f1b9015bd54652a5994ec99c4cc7d';
	static MIX_PANEL_TOKEN = '515f59129f51bd29d1361ca5b89c9192';
	static MIX_APP_NAME = 'Uplers Talent Solutions';
	static MIX_MIXPANEL_ENABLED = true;
	static ATSPROTOCOL = 'https://';
	static ATSDOMINLIVE='ats.uplers.com/api/';
	static ATSDOMIN="13.233.146.211/api/"
	static PAYPAL_CLIENT_KEY = "";
	static UPSCREEN_DOMAIN = 'https://assessment.upscreen.ai/'
	static ATS_URL=NetworkInfo.ATSPROTOCOL+NetworkInfo.ATSDOMINLIVE
	static RESUME_PATH = 'https://newbeta-adminapi.Uplers.com/media/talentresume'
}

// export class NetworkInfo {
// 	static PROTOCOL = 'http://';
// 	// static DOMAIN = '3.218.6.134:9085/' ;
// 	// static ADMINDOMAIN = '3.218.6.134:9082/'
// 	// static FILEDOMAIN = '3.218.6.134:90/';

// 	static DOMAIN = '3.82.177.154:9085/' ; 
// 	// static DOMAIN = '3.82.177.154:9086/' ;
// 	static ADMINDOMAIN = '3.82.177.154:9082/'
// 	static FILEDOMAIN = '3.82.177.154:90/';
	
// 	static NETWORK = NetworkInfo.PROTOCOL + NetworkInfo.DOMAIN;
// 	static FILENETWORK = NetworkInfo.PROTOCOL + NetworkInfo.FILEDOMAIN; 
// 	// static MIX_PANEL_TOKEN = '166f1b9015bd54652a5994ec99c4cc7d';
// 	static MIX_PANEL_TOKEN = '515f59129f51bd29d1361ca5b89c9192';
// 	static MIX_APP_NAME = 'Uplers Talent Solutions';
// 	static MIX_MIXPANEL_ENABLED = true;
// 	static ATSPROTOCOL = 'https://';
// 	static ATSDOMINLIVE='ats.uplers.com/api/';
// 	static ATSDOMIN="ats.internalstaging.uplers.in/api/"
// 	static PAYPAL_CLIENT_KEY = "";
// 	static UPSCREEN_DOMAIN = 'https://assessment.upscreen.ai/'
// 	static ATS_URL=NetworkInfo.ATSPROTOCOL+NetworkInfo.ATSDOMINLIVE
// 	static RESUME_PATH = 'https://newbeta-adminapi.Uplers.com/media/talentresume'
// }

// export class NetworkInfo {
// 	static PROTOCOL = 'https://';
// 	static DOMAIN = 'newbeta-clientapi.Uplers.com/' ;
// 	static ADMINDOMAIN = 'newbeta-adminapi.Uplers.com/'
// 	static FILEDOMAIN = 'newbeta-adminapi.Uplers.com/';
// 	static Environment = 'Live';
// 	static NETWORK = NetworkInfo.PROTOCOL + NetworkInfo.DOMAIN;
// 	static FILENETWORK = NetworkInfo.PROTOCOL + NetworkInfo.FILEDOMAIN; 
// 	// static MIX_PANEL_TOKEN = '166f1b9015bd54652a5994ec99c4cc7d';
// 	static MIX_PANEL_TOKEN = '515f59129f51bd29d1361ca5b89c9192';
// 	static MIX_APP_NAME = 'Uplers Talent Solutions';
// 	static MIX_MIXPANEL_ENABLED = true;
// 	static ATSPROTOCOL = 'https://';
// 	static ATSDOMINLIVE='ats.uplers.com/api/';
// 	static ATSDOMIN="ats.internalstaging.uplers.in/api/"
// 	static PAYPAL_CLIENT_KEY = "";
// 	static UPSCREEN_DOMAIN = 'https://assessment.upscreen.ai/'
// 	static ATS_URL=NetworkInfo.ATSPROTOCOL+NetworkInfo.ATSDOMINLIVE
// 	static RESUME_PATH = 'https://newbeta-adminapi.Uplers.com/media/talentresume'
// }
// export class NetworkInfo {
//     static PROTOCOL = 'https://';
//     static DOMAIN = 'newbeta-clientapi.Uplers.com/' ;
//     static ADMINDOMAIN = 'newbeta-adminapi.Uplers.com/'
//     static FILEDOMAIN = 'newbeta-adminapi.Uplers.com/';
//     static Environment = 'Live';
//     static NETWORK = NetworkInfo.PROTOCOL + NetworkInfo.DOMAIN;
//     static FILENETWORK = NetworkInfo.PROTOCOL + NetworkInfo.FILEDOMAIN; 
//     // static MIX_PANEL_TOKEN = '166f1b9015bd54652a5994ec99c4cc7d';
//     static MIX_PANEL_TOKEN = '515f59129f51bd29d1361ca5b89c9192';
//     static MIX_APP_NAME = 'Uplers Talent Solutions';
//     static MIX_MIXPANEL_ENABLED = true;
//     static ATSPROTOCOL = 'https://';
//     static ATSDOMINLIVE='ats.uplers.com/api/';
//     static ATSDOMIN="ats.internalstaging.uplers.in/api/"
//     static PAYPAL_CLIENT_KEY = "";
//     static UPSCREEN_DOMAIN = 'https://assessment.upscreen.ai/'
//     static ATS_URL=NetworkInfo.ATSPROTOCOL+NetworkInfo.ATSDOMINLIVE
// 	static RESUME_PATH = 'https://newbeta-adminapi.Uplers.com/media/talentresume'
// }



// export class NetworkInfo {
//     static PROTOCOL = 'https://';
//     static DOMAIN = 'newbeta-clientapi.Uplers.com/' ;
// 	static RESUME_PATH = 'https://newbeta-clientapi.Uplers.com/talent/talentresume'
//     static ADMINDOMAIN = 'newbeta-adminapi.Uplers.com/'
//     static FILEDOMAIN = 'newbeta-adminapi.Uplers.com/';
//     static Environment = 'Live';
//     static NETWORK = NetworkInfo.PROTOCOL + NetworkInfo.DOMAIN;
//     static FILENETWORK = NetworkInfo.PROTOCOL + NetworkInfo.FILEDOMAIN; 
//     // static MIX_PANEL_TOKEN = '166f1b9015bd54652a5994ec99c4cc7d';
//     static MIX_PANEL_TOKEN = '515f59129f51bd29d1361ca5b89c9192';
//     static MIX_APP_NAME = 'Uplers Talent Solutions';
//     static MIX_MIXPANEL_ENABLED = true;
//     static ATSPROTOCOL = 'https://';
//     static ATSDOMINLIVE='ats.uplers.com/api/';
//     static ATSDOMIN="ats.internalstaging.uplers.in/api/"
//     static PAYPAL_CLIENT_KEY = "";
//     static UPSCREEN_DOMAIN = 'https://assessment.upscreen.ai/'
//     static ATS_URL=NetworkInfo.ATSPROTOCOL+NetworkInfo.ATSDOMINLIVE
// }

// export class NetworkInfo {
//     static PROTOCOL = 'https://';
//     static DOMAIN = 'newbeta-clientapi.Uplers.com/' ;
//     static FILEDOMAIN = 'newbeta-admin.Uplers.com/';
//     static Environment = 'Live';
//     static NETWORK = NetworkInfo.PROTOCOL + NetworkInfo.DOMAIN;
//     static FILENETWORK = NetworkInfo.PROTOCOL + NetworkInfo.FILEDOMAIN; 
//     // static MIX_PANEL_TOKEN = '166f1b9015bd54652a5994ec99c4cc7d';
//     static MIX_PANEL_TOKEN = '2d8a23d7de62557facd2ed61d5e4b6fb';
//     static MIX_APP_NAME = 'Uplers Talent Solutions';
//     static MIX_MIXPANEL_ENABLED = true;
//     static ATSPROTOCOL = 'https://';
//     static ATSDOMINLIVE='ats.uplers.com/api/';
//    // static ATSDOMIN="ats.internalstaging.uplers.in/api/"
//     static ATSDOMIN="atsstaging.uplers.com/api/"
//     static PAYPAL_CLIENT_KEY = "";
//     static UPSCREEN_DOMAIN = 'https://assessment.upscreen.ai/'
//     static ATS_URL=NetworkInfo.ATSPROTOCOL+NetworkInfo.ATSDOMINLIVE
// }
export class UPScreen {
	static GET_QUESTIONS = 'api/external/assessment-question';
	static UPDATE_QUESTIONS = 'api/external/update-assessment-question'
    static CHECK_ASSESSMENT_QUESTION_GENERATED = 'api/external/check-assessment-question-generated'
}

export class SubDomain {
	static ACCOUNT = 'Account/';	
	static ROLE_HIRING_DETAILS = 'RoleAndHiringTypeDetails/';
	static SKILL_AND_BUDGET_DETAILS = 'SkillAndBudgetDetails/';
	static POST_JOB = 'PostJob/';
	static JOB_DESCRIPTION = 'JobDescription/';
	static TEXT_EXTRACTION = 'TextExtraction/';
	static CLIENT_HAPPINESS_SURVEY = 'ClientHappinessSurveyFeedback/';
	static DASHBOARD = 'Dashboard/';
	static INTERVIEW = 'Interview/';
	static USER = "User/";
	static CUSTOM_ASSESSMENT = 'customassessment/'
	static SUBSCRIPTION = "Subscription/"
	static JOB_POST_STEPS_INFO = 'JobPostStepsInfo/';
	static TRANSPARENT_PRICING = 'TransparentPricing/';
	static ATSCLIENT="client/";
	static TALENT="Talent/";
	static ON_BOARD = 'OnBoard/';
}

export class MethodType {
	static GET = '/get';
	static POST = '/post';
	static PUT = '/update';
	static DELETE = '/delete';
}

export class UserAPI {
	static LOGIN = 'Login';
	static LOGOUT = 'LogOut';
	static SIGNUP = 'SignUp';
	static SAVEENQUIRY = 'SaveEnquiry';
	static VERIFYOTP = 'VerifyOTP';
	static RESENDOTP = 'ResendOTP';
	static SOCIALMEDIALOGIN = 'SocialMediaLoginCallBack';
	static LOGIN_FORM_EXISTING = 'LoginFromExistingClientPortal';
	static REDIRECT_TO_DASHBOARD = 'RedirectToDashboard';
	static FORGOTPASSWORD = 'ForgotPassword';
	static SETPASSWORD = 'SetPassword';
	static SET_PASSWORD_URL= 'ValidateSetPasswordUrl';
	static LOGIN_WITH_EMAIL = 'LoginWithEmail';
	static CHECK_COMPANY_NAME = "CheckCompanyName";
	static TRACKING_LEAD = "TrackingLead";
	static RELOAD_LOCALSTORAGE_WHILE_REFRESH = "ReloadLocalStorageWhileRefresh";
	static CHANGE_PASSWORD = "ChangePassword";
	static GET_CONTACT_DETAILS_AUTO_FILL = "GetContactDetailsAutofill";
}
export class RegisterSteps{
	static  GET_ROLE_HIRING_DETAILS = 'GetRoleAndHiringTypeDetails';
	static  SAVEROLEANDHIRINGTYPEDETAILS = 'SaveRoleAndHiringTypeDetails';
	static  GETTALENTSROLES = 'GetTalentsRoles';
	static TEXTEXTRACTION = 'TextExtraction/';
	// static EXTRACTTEXT = 'ExtractTextUsingPython';
	static EXTRACTTEXT = 'ExtractTextUsingclaudAI';
	static EXTRACTTEXTAI = 'ExtractTextUsingclaudAI';
	static GET_SKILLS_AND_BUDGET = 'GetSkillAndBudgetDetails';
	static GET_SKILLS = 'GetSkills';
	static GET_CURRENCY = 'GetCurrency';
	static SAVE_SKILLS_AND_BUGET = 'SaveSkillAndBudgetDetails';
	static EMPLOYEEMENTDETAILS = 'EmploymentDetails/';
	static GETACHIEVEMENT  = 'GetAchievementWithUplersReason';
	static SAVEEMPLOYEMENTDETAILS = 'SaveEmploymentDetails';
	static GETTIMEZONEPREFERENCE = 'GetTimeZonePreference';
	static GETTALENTTIMEZONE = 'GetTalentTimeZone';
	static GETSTARTENDTIME  = 'GetStartEndTime';
	static GETCONTACTTIMEZONE = 'GetContactTimeZone';
	static GET_COUNTRY_LIST= 'GetCountryListByCountryCodeOrPostalcode';
	static SAVE_STEPS_INFO = 'SaveStepsInfo';
	static GET_HIRING_TYPE_PRICING = 'GetHiringTypePricing';
	static GET_PAYROLL_TYPE = 'GetPayrollType';
	static FETCH_COUNTRIES_BASED_ON_CITY= 'FetchCountriesBasedonCity';
	static GET_FREQUENCY = 'GetFrequency';
	static GET_AUTOCOMPLETE_CITY_STATE = 'GetAutoCompleteCityStateWise';
	static GET_NEAR_BY_CITIES = 'GetNearByCities';
	static GET_HIRING_TYPE_PRICING_DETAILS = 'GetHiringTypePricingDetails';
}
export class JobPost{
	static GET_UNFINISHED_JOB ='GetUnfinishedJobPostDetails'
	static PREVIEW = 'Preview'
	static SAVE_STANDOUT_Details= 'SaveStandOutDetails'
	static CREATE_JOB_POST = 'CreateJobPost'
	static UPDATE_PREVIEW_DETAILS = 'UpdatePreviewDetails'
	static GET_ACTIVE_CLOSED_JOBPOST = 'GetActiveClosedJobPosts'
	static GET_SHORT_LISTED_TALENTS= 'GetShortlistedTalents'
	static GET_TIMEZONE = 'GetTimeZone';
	static GET_TALENT_DETAILS  = 'GetTalentDetails';
	static CLOSE_HR_WARNING_MSG = 'CloseHR_WarningMsg';
	static SAVE_INTERVIEW_DETAILS = 'SaveInterviewDetails'
	static GET_TALENT_REJECT_REASONS = "GetTalentRejectReasons";
	static UPDATE_TALENT_STATUS = "UpdateTalentStatus"
	static GET_SHORTLISTED_TALENT_HR = "GetShortlistedTalentsHR";
	static DOWNLOAD_RESUME = "DownloadResume";
	static AIPROFILEVIEW="AIProfileView";
	static GET_HR_CLOSE_REASONS = "GetHRCloseReasons";
	static CLOSE_HR_VALIDATION = "CloseHRValidation";
	static CTP_TALENT_DETAILS = "CTPTalentDetails";
	static CLOSE_HR = "CloseHR";
	static FEEDBACK = "Feedback";
	static VALIDATION_INTERVIEW_SLOT = "ValidateInterviewTimeSlots";
	static GET_RESCHEDULE_REASONS = "GetRescheduleReasons";
	static GET_TALENT_STATUS = 'GetTalentStatus';
	static ADD_REMOVE_TALENT_BOOKMARK = 'AddRemoveTalentBookmark';
	static UPDATE_CREDIT_HR_TALENT_STATUS = "UpdateCreditHRTalentStatus";
	static RESCHEDULE = "Reschedule";
	static HR_TALENT = 'hr_talents';
	static HR_TALENT_VIDEO = 'hr_talent_video';
	static DUMPATSDATATOUTS="DumpATSDatatoUTSForShortlisted";
	static HRDETAILSFORSHORTLISTED="HrDetailsForShortlisted";
	static GETUTSTALENTSFORSHORTLISTED="GetUTSTalentsForShortlisted";
	static HRTALENTSCOUNT="hr_talents_count";
	static UNLOCKSINGLETALENT="UnlockSingleTalent";
	static UNLOCKMULTIPLETALENT="UnlockMultipleTalents";
	static PROFILESTATUS="profile_status";
	static UPDATETALENTBOOKMARKANDSTATUS = "UpdateTalentBookmarkAndStatus";
	static PROFILELIST="profile_list";
	static GENERATEUID="GenerateUniqueId";
	static TALENT_REJECTION_REASON = 'GetCreditTalentRejectReasons';
	static PROFILECHECK="profile_check";
	static GETCLIENTFEEDBACK = "GetClientFeedback";
	static DELETE_DRAFT = "DeleteDraftJob";
	static GET_GIVEN_INTERVIEW_SLOTS = "GetTheGivenInterviewSlots";
	static CANCEL_TALENT_INTERVIEW = "CancelTalentInterview";
	static CAPTURE_SCREENING_ACTIONS = "CaptureScreeningActions";
	static NOTES = "notes/";
	static CREATE = "create";
	static DELETE = "delete";	
	static ADD_DELETE_NOTES_DATA = "AddDeleteNotesData";
	static PAUSE_JOB = "PauseJob";
	static REOPEN_HR = "ReopenHR"
	static GET_HR_PAUSE_REASONS = "GetHRPauseReasons";
	static CLONE_HR = "CloneHR";
	static DELETE_HR_POC = 'DeleteHRPOC';
	static CAPTURE_VIDEO_RESUME_ACTIONS = "CaptureVideoResumeActions";
	static GET_JOB_TYPE = "GetJobTypes";
	static SEND_ATS_UPDATE_ON_EDIT_HR = 'SendATSUpdateOnEditHR';
	static CLOSE_HR_CHECKS = "CloseHRChecks";
	static GET_REJECTION_REASON_FOR_TALENT= "GetRejectionReasonForTalent";
}
export class JobDescription{
	static UPLOAD_FILE = 'UploadFile';
	static SAVE_JD_Details = 'SaveJDDetails';
}

export class CompanyDetails{
	static COMPANY = 'Company/';
	static GET_DETAILS = 'GetDetails';
	static UPDATE_DETAILS = 'UpdateDetails';
	static DELETE_CULTURE_IMAGE = 'DeleteCultureImage';
	static DELETE_YOUTUBE_LINK = 'DeleteYouTubeDetails';
	static GET_COMPANY_FUNDING_DETAILS = "GetTotalFundingDetails"
	static COMPANY_PERKS = "CompanyPerks";
	static UPDATE_HRPOC_DETAILS = 'EditHRPOC';
}
export class TextExtraction {
	// static EXTRACT_JD_USING_PYTHON = 'ExtractJDDetailsUsingPython'
	static EXTRACT_JD_USING_PYTHON ='ExtractJDDetailsUsingPython'
	// static EXTRACT_SKILLS_USING_PYTHON = 'ExtractSkillsUsingPython'
	static EXTRACT_SKILLS_USING_PYTHON = 'ExtractSkillsUsingclaudAI'
	static GET_ALL_DETAILS_FROM_TEXT = 'GetAllDetailsFromText'
	static EXTRACT_JOB_DESCRIPTION_CLAUD_AI = 'ExtractJobDescriptionUsingClaudAI'
	static SAVE_STEP_INFO_JOB = 'SaveStepsInfoPostaJob'
}
export class ClientHappinessSurveyFeedback {
	static HAPPINESS_SURVEY ='HappinessSurvey';
	static GET_QUESTION = 'GetQuestions';
	static SUBMIT_FEEDBACK = 'SubmitFeedback';
	static UTS_FEEDBACK = 'UTSFeedBack';
}
export class Dashboard {
	static DASHBOARD_DETAILS = 'DashboardDetails';
	static GETSALDETAILS = 'GetSLADetails';
	static SHORTLISTED_TALENT_DETAILS = 'GetShortlistedTalentDetails';
	static UPDATE_STAND_OUT_DETAILS= 'UpdateStandoutDetails';
	static DASHBOARD_COUNTS = 'GetDashboardCounts';
	static SHOW_JOBPOST_ANSWER_SECTION = "ShowJobPostAnswerSection";
	static GET_COMPANY_POC_DETAIL =  "GetCompanyPOCDetail";
	static GET_UNICON_ROLES="Get_UnicornRoles";
	static ROLE_TALENTS="role_talents";
	static GET_CALL_BACK = "GetCallBack";
}

export class Users {
	static GET_COMPANY_USERS = "GetCompanyUsers";
	static GET_USERS_ROLES = "GetUserRoles";
	static INVITE_USERS = "UserAddUpdateDelete";
}

export class Subscription_API {
	static GET_CREDIT_TRANSACTION_HISTORY = "GetCreditTransactionHistory";
	static GET_INVOICE_LIST = "GetInvoiceList";
	static GET_SUBSCRIPTION_PLAN = "GetSubscriptionPlans";
	static GET_EXPIRY_JOBS = "GetExpiryJobs";
	static RENEW_THE_EXPIRED_JOB = "RenewTheExpiredJob";
	static GET_INVOICE_DETAIL = "GetInvoiceDetail";
	static SAVE_PAYMENT_INFO = "SavePaymentInfo";
	static CURRENT_CREDIT_DETAILS="GetCurrentCreditDetails";
	static GET_RAZORPAY_DETAILS ="GetRazorPayDetails";
	static GET_CREDIT_UTILIZATION = "GetCreditUtilizationAmountBasedOnAction";
	static UPDATE_COMPANY_PAYMENT_INFORMATION = "UpdateCompanyPaymentInformation";
	static GET_COUNTRY_STATES = "GetCountryStates";	
	static CREATE_RAZORPAY_ORDER_DETAILS ="CreateRazorPayOrder";
}

export class Currency_Convert {
	static CURRENCY_CONVERT = "https://api.exchangerate-api.com/v4/latest/USD";
}

export class On_Board {
	static GET_PRE_ONBOARDING_DETAILS = 'GetPreOnBoardingDetails';
	static UPDATE_ONBOARDING_DETAILS = 'UpdateOnBoardingDetails';
	static UPLOAD_LEAVEPOLICY = 'UploadLeavePolicy'
}



// export class NetworkInfo {
//     static PROTOCOL = 'https://';

//     static DOMAIN = process.env.REACT_APP_ENVIRONMENT === 'live' 
//         ? 'newbeta-clientapi.Uplers.com/' 
//         : '3.82.177.154:9085/';
        
//     static ADMINDOMAIN = process.env.REACT_APP_ENVIRONMENT === 'live'
//         ? 'newbeta-adminapi.Uplers.com/'
//         : '3.82.177.154:9082/';

//     static FILEDOMAIN = process.env.REACT_APP_ENVIRONMENT === 'live'
//         ? 'newbeta-adminapi.Uplers.com/'
//         : '3.82.177.154:90/';

//     static Environment = process.env.REACT_APP_ENVIRONMENT || 'development';
    
//     static NETWORK = NetworkInfo.PROTOCOL + NetworkInfo.DOMAIN;
//     static FILENETWORK = NetworkInfo.PROTOCOL + NetworkInfo.FILEDOMAIN;
    
//     static MIX_PANEL_TOKEN = process.env.REACT_APP_ENVIRONMENT === 'live'
//         ? '515f59129f51bd29d1361ca5b89c9192'
//         : '2d8a23d7de62557facd2ed61d5e4b6fb';

//     static MIX_APP_NAME = 'Uplers Talent Solutions';
//     static MIX_MIXPANEL_ENABLED = true;

//     static ATSPROTOCOL = 'https://';
//     static ATSDOMINLIVE = 'ats.uplers.com/api/';
//     static ATSDOMIN = process.env.REACT_APP_ENVIRONMENT === 'live'
//         ? 'ats.uplers.com/api/'
//         : 'ats.internalstaging.uplers.in/api/';

//     static PAYPAL_CLIENT_KEY = '';
//     static UPSCREEN_DOMAIN = 'https://assessment.upscreen.ai/';
//     static ATS_URL = NetworkInfo.ATSPROTOCOL + NetworkInfo.ATSDOMINLIVE;
//     static RESUME_PATH = process.env.REACT_APP_ENVIRONMENT === 'live'
//         ? 'https://newbeta-adminapi.Uplers.com/media/talentresume'
//         : 'https://newbeta-clientapi.Uplers.com/talent/talentresume';
// }